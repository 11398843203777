/* src/components/ContactUs.css */
.contact-us-container {
    font-family: Roboto, Arial, sans-serif !important;
  }
  
  .contact-us-container h1,
  .contact-us-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-us-container p {
    color: #666;
    line-height: 1.6;
  }
  
  .contact-us-container .text-center {
    text-align: center;
  }
  
  .contact-us-container .mb-4 {
    margin-bottom: 1.5rem;
  }
  